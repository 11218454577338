// import MouseFollower from "mouse-follower";
// import gsap from "gsap";



import Component from 'gia/Component'

export default class cursor extends Component {
  constructor(element) {
    super(element)
  }

  mount() {

    // MouseFollower.registerGSAP(gsap)

    // const cursor = new MouseFollower();
    // const el = document.querySelector('.c-artists--list');

    // el.addEventListener('mouseenter', () => {
    //   cursor.hide();
    // });

    // el.addEventListener('mouseleave', () => {
    //   cursor.show();
    // });


    // var cursor = $('.c-cursor')

    // $(function () {
    //   $('.swiper-button-next')
    //     .mouseover(function () {
    //       $('.c-cursor').addClass('active').text('NEXT >')
    //     })
    //     .mouseleave(function () {
    //       $('.c-cursor').removeClass('active').text('')
    //     })

    //   $('.swiper-button-prev')
    //     .mouseover(function () {
    //       $('.c-cursor').addClass('active').text('< PREV')
    //     })
    //     .mouseleave(function () {
    //       $('.c-cursor').removeClass('active').text('')
    //     })
    // })


  }
}
