// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class modal extends Component {
	constructor(element) {
		super(element);
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		$('.tab_content_login').hide();
		$('ul.tabs_login li:first').addClass('active_login').show();
		$('.tab_content_login:first').show();
		$('ul.tabs_login li').click(function () {
			$('ul.tabs_login li').removeClass('active_login');
			$(this).addClass('active_login');
			$('.tab_content_login').hide();
			var activeTab = $(this).find('a').attr('href');
			if ($.browser.msie) {
				$(activeTab).show();
			} else {
				$(activeTab).show();
			}
			return false;
		});

		$('#login-modal-open').on('click', function (e) {
			$('#login-modal').addClass('active');
			e.preventDefault();
		});

		$('#apply').on('click', function (e) {
			$('#apply-modal').addClass('active');
			e.preventDefault();
		});

		$('.modal-close').on('click', function (e) {
			e.preventDefault();
			$('.c-modal').removeClass('active');
		});
	}
}
