import Component from 'gia/Component'

export default class loader extends Component {
  constructor(element) {
    super(element)
  }

  mount() {
    $(window).on('load', function () {
      setTimeout(function () {
        $('#loader').removeClass('loading')
      }, 1500)
    })
  }
}
