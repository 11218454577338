import Swiper, { Autoplay, Navigation, EffectFade, Pagination } from 'swiper'
import 'swiper/swiper.scss' // core Swiper

Swiper.use([Autoplay, Navigation, Pagination, EffectFade])

import Component from 'gia/Component'

export default class slider extends Component {
  constructor(element) {
    super(element)
  }

  mount() {
    let swiperInstances = []
    $('.swiper').each(function (index, element) {
      //some-slider-wrap-in
      const $this = $(this)
      $this.addClass('instance-' + index) //instance need to be unique (ex: some-slider)
      $this
        .parent()
        .find('.swiper-pagination')
        .addClass('pagination-' + index)
      $this
        .parent()
        .find('.swiper-button-prev')
        .addClass('prev-' + index) //prev must be unique (ex: some-slider-prev)
      $this
        .parent()
        .find('.swiper-button-next')
        .addClass('next-' + index) //next must be unique (ex: some-slider-next)
      swiperInstances[index] = new Swiper('.instance-' + index, {
        //instance need to be unique (ex: some-slider)
        // your settings ...
        modules: [Autoplay, Navigation, EffectFade, Pagination],
        loop: true,
        autoplayDisableOnInteraction: false,
        slidesPerView: 1,
        //autoHeight: true,
        autoplay: {
          delay: 3000,
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },

        navigation: {
          prevEl: '.prev-' + index, //prev must be unique (ex: some-slider-prev)
          nextEl: '.next-' + index, //next must be unique (ex: some-slider-next)
        },
        pagination: {
          el: '.pagination-' + index,
          clickable: 'true',
          type: 'fraction',
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '<em>' + listArray[index] + '</em>' + '<i></i>' + '<b></b>' + '</span>';
          },
        },

      })
    })

    // Now you can call the update on a specific instance in the "swiperInstances" object
    // e.g.
    // swiperInstances[3].update();
    //or all of them
    setTimeout(function () {
      for (const slider of swiperInstances) {
        slider.update()
      }
    }, 50)
  }
}
