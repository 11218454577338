// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class dashboard extends Component {
	// setup
	constructor(element) {
		super(element);

		this.ref = {};
	}

	mount() {
		$('.acf-required').html('<sup>[Required]</sup>');

		$('.tabcontent').not(':first').hide();

		$('.c-dashboard_toggle a').click(function (event) {
			$('.tabcontent').hide();
			$('.c-dashboard_toggle a').removeClass('active');
			$(this).addClass('active');
			var tab = $(this).data('tab');
			$('#' + tab).show();
			return false;
		});

		$('.message-tabcontent').not(':first').hide();

		$('.c-dashboard_message-toggle a').click(function (event) {
			$('.message-tabcontent').hide();
			$('.c-dashboard_message-toggle a').removeClass('active');
			$(this).addClass('active');
			var tab = $(this).data('tab');
			$('#' + tab).show();
			return false;
		});
	}
}
