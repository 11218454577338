import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitText from '../vendor/SplitText.min.js'

import Component from 'gia/Component'

export default class inview extends Component {
  constructor(element) {
    super(element)
  }

  mount() {
    gsap.registerPlugin(ScrollTrigger)

    const visible = gsap.utils.toArray('[data-scroll]')

    visible.forEach((visible) => {
      gsap.from(visible, {
        scrollTrigger: {
          start: 'top bottom',
          end: 'bottom top',
          trigger: visible,
          toggleClass: 'in-view',
        },
      })
    })

    gsap.utils.toArray('.lerp').forEach((section) => {
      gsap.to(section, {
        // y: -200,
        yPercent: -10,

        ease: 'power2',
        scrollTrigger: {
          trigger: section,
          scrub: true,
        },
      })
    })

    function setupSplits() {
      const targets = gsap.utils.toArray('.heading > h1, .c-cards h2')
      targets.forEach((target) => {
        let SplitClient = new SplitText(target, {
          type: 'lines',
          linesClass: 'line',
          reduceWhiteSpace: false,
        })
        let chars = SplitClient.chars //an array of all the divs that wrap each character
      })
    }

    setupSplits()

    // let sections = gsap.utils.toArray('.c-horizontal-block')

    // let scrollTween = gsap.to(sections, {
    //   xPercent: -100 * (sections.length - 1),
    //   ease: 'none', // <-- IMPORTANT!
    //   scrollTrigger: {
    //     trigger: '.c-horizontal-blocks',
    //     pin: true,
    //     scrub: 0.1,
    //     //snap: directionalSnap(1 / (sections.length - 1)),
    //     start: 'center center',
    //     end: '+=2000',
    //   },
    // })

    if ($('._full').length > 0) {
      $('._full').parent('section').addClass('_pb');
    }
  }
}
