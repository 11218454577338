import gsap from 'gsap'

// import GIA dependancy 
import Component from 'gia/Component';


// Extend the GIA component
export default class hover extends Component {

	constructor(element) {
		super(element);
	}

	mount() {


		var $circle = $('.c-cursor');

		function moveCircle(e) {
			gsap.to($circle, 1, {
				css: {
					left: e.clientX,
					top: e.clientY,
				}
			});
		}

		$(window).on('mousemove', moveCircle);


		$('.artist').hover(
			function () {
				// mouseover
				//$(".c-cursor").addClass("active");

				var value = $(this).find("a").attr('data-src');

				$(".c-cursor img").attr("src", value);

			},

			function () {
				$(".c-cursor img").removeClass("active");

				//setTimeout(function () {
				var value = $(this).find("a").attr('data-src');
				$(".c-cursor img").attr("src", "");
				// }, 1000);

			});

		$('button#gallery').click(function () {
			if ($(this).parents().find(".c-artists--list").hasClass("--list")) {
				$(this).parents().find(".c-artists--list").removeClass("--list");
				$(this).parents().find(".c-cursor").addClass("active");
			}
			if (!$(this).parents().find(".c-artists--list").hasClass("--gallery")) {
				$(this).parents().find(".c-artists--list").addClass("--gallery");
				$(this).parents().find(".c-cursor").removeClass("active");

			}
			//$(this).parents().find(".c-artists--list").addClass("--gallery");
		});


		//	$(".c-artists--list").addClass('--gallery')



		$('button#list').click(function () {
			if ($(this).parents().find(".c-artists--list").hasClass("--gallery")) {
				$(this).parents().find(".c-artists--list").removeClass("--gallery");
				$(this).parents().find(".c-cursor").removeClass("active");

			}
			if (!$(this).parents().find(".c-artists--list").hasClass("--list")) {
				$(this).parents().find(".c-artists--list").addClass("--list");
				$(this).parents().find(".c-cursor").addClass("active");



			}
			//$(this).parents().find(".c-artists--list").addClass("--gallery");

		})

	}
}

