import Component from 'gia/Component'

export default class basetheme extends Component {
	constructor(element) {
		super(element)
	}

	mount() {



	}
}


