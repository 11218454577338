// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class filter extends Component {
	constructor(element) {
		super(element);
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		// var allPanels = $('#filter-form > .field-group > fieldset');
		// $('#filter-form > .field-group > fieldset:first-of-type, #filter-form > .field-group > fieldset:first-of-type > .toggle ').addClass('active');

		$('.field-group .toggle').click(function () {
			$(this).parent().siblings().removeClass('active');

			$(this).parent().addClass('active');
			//$(this).next().toggleClass('active');
			return false;
		});

		$('#filter-form').on('click', 'input', function (e) {
			var service = $('input[name="service"]:checked').val();
			var location = $('input[name="location"]:checked').val();
			var level = $('input[name="level"]:checked').val();

			//console.log(result);
			var ajax_data = {
				service: service,
				location: location,
				level: level,
				action: 'filter',
				nonce: winc.nonce,
			};

			$.ajax({
				url: winc.ajaxurl,
				data: ajax_data,
				type: 'post',
				success: function (response) {
					$('#js-filter').html(response);
				},
				error: function (response) {
					console.warn(response);
				},
			});
			//return false;
		});
	}
}
