// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class messages extends Component {
	constructor(element) {
		super(element);
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		var rowCount = $('.new_message_counter').length;

		if (rowCount !== 0) {
			$('#new-messages-count').text(`[${rowCount}]`);
		}

		$('.c-messages-table').on('click', 'a.view', function (e) {
			var post_id = $(this).attr('data-id');

			var ajax_data = {
				post_id: post_id,
				action: 'message_read',
				nonce: winc.nonce,
			};

			$.ajax({
				url: winc.ajaxurl,
				data: ajax_data,
				type: 'POST',
				success: function (response) {
					console.log('success');
				},
				error: function (response) {
					console.log('fail');
				},
			});
		});
	}
}
