import Component from 'gia/Component'

export default class nav extends Component {
  constructor(element) {
    super(element)
  }

  mount() {
    var el = $('label[for=hamburger]')

    $(document).ready(function () {
      $('.c-hamburger').click(function () {
        $(this).toggleClass('open')
        $('.c-nav').toggleClass('open')
        el.text(el.text() == 'Menu' ? 'Close' : 'Menu')
      })

      $('.c-nav li > .sub-menu')
        .parent()
        .hover(function () {
          var submenu = $(this).children('.sub-menu')
          if ($(submenu).is(':hidden')) {
            $(submenu).slideDown(200)
          } else {
            $(submenu).slideUp(200)
          }
        })
      $("#source").appendTo("#destination");

      // $('.c-nav .menu-item-has-children').on("mouseover", function (e) {
      //   e.preventDefault()
      //   $(this)
      //     .siblings('.active')
      //     .toggleClass('active')
      //     .children()
      //     .toggleClass('active')
      //   $(this)
      //     .delay(5000)
      //     .toggleClass('active')
      //     .children()
      //     .toggleClass('active')
      // })
    })
  }
}
